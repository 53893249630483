// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_lemon from "../../../../../../files/icons/rency/square_lemon.svg";
import square_lime from "../../../../../../files/icons/rency/square_lime.svg";
import square_grass from "../../../../../../files/icons/rency/square_grass.svg";
import square_cucumber from "../../../../../../files/icons/rency/square_cucumber.svg";



/**
 * MkitHero section
 * lists most important informations about M-KIT with large M-KIT img
 * @returns HTML code with MkitHero section for M-KIT page
 */
export default function MkitHero() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>  
            <div className="fade-in-750">
                <div className="navbar-size-compensation bg-divelit-grey-90">
                    <div className="products-mkit-hero-bg-img pt-3 pb-3 pt-lg-5 pb-lg-5">
                        <Container className="pt-3 pt-lg-5 pb-3 pb-lg-5">
                            <Row>
                                <Col xs={12} sm={12}>
                                    <div className="fade-in-750-down-to-top">
                                        <h2 className="font-white font-weight-bold">
                                            <span style={{fontWeight: "600"}}>{translation.header.part1}</span><span style={{fontSize: "128%"}}>e</span><span style={{fontWeight: "600"}}>{translation.header.part2}</span> <span className="font-divelit-lemon" style={{fontWeight: "600"}}>{translation.header.part3}</span>
                                        </h2>
                                    </div>
                                    <Row className="fade-in-1000-down-to-top d-flex flex-row align-items-center mt-4 mt-md-5 ms-0 ms-lg-5 me-5">
                                        <Col xs={12} sm={12} lg={1}>
                                            <img src={square_lemon} className="project-square-img w-fit-content" alt={translation.alt.square_lemon}/>
                                        </Col>
                                        <p className="paragraph-medium mt-2 mt-lg-0 mb-0 font-white w-fit-content">{translation.squares.part1}</p>
                                    </Row>
                                    <Row className="fade-in-1250-down-to-top d-flex flex-row align-items-center mt-4 mt-md-5 ms-0 ms-lg-5 me-5">
                                        <Col xs={12} sm={12} lg={1}>
                                            <img src={square_lime} className="project-square-img w-fit-content" alt={translation.alt.square_lime}/>
                                        </Col>
                                        <p className="paragraph-medium mt-2 mt-lg-0 mb-0 font-white w-fit-content">{translation.squares.part2}</p>
                                    </Row>
                                    <Row className="fade-in-1500-down-to-top d-flex flex-row align-items-center mt-4 mt-md-5 ms-0 ms-lg-5 me-5">
                                        <Col xs={12} sm={12} lg={1}>
                                            <img src={square_grass} className="project-square-img w-fit-content" alt={translation.alt.square_grass}/>
                                        </Col>
                                        <p className="paragraph-medium mt-2 mt-lg-0 mb-0 font-white w-fit-content">{translation.squares.part3}</p>
                                    </Row>
                                    <Row className="fade-in-1750-down-to-top d-flex flex-row align-items-center mt-4 mt-md-5 ms-0 ms-lg-5 me-5">
                                        <Col xs={12} sm={12} lg={1}>
                                            <img src={square_cucumber} className="project-square-img w-fit-content" alt={translation.alt.square_cucumber}/>
                                        </Col>
                                        <p className="paragraph-medium mt-2 mt-lg-0 mb-0 font-white w-fit-content">{translation.squares.part4}</p>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
}
