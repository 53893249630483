// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files

// components 
import BtnBgLemonContact from "../../../../../../components/btn/btn-bg-lemon-contact";

/**
 * MKitContact section
 * section with button that redirects users to our CONTACT page
 * @returns HTML code with MKitContact section for M-KIT page
 */
export default function MKitContact() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>  
            <div className="bg-divelit-grey-90" style={{borderBottom: "1px solid var(--divelit-grey-80)", borderTop: "1px solid var(--divelit-grey-80)"}}>
                <div className="home-experts-bg-img pt-5 pb-5">
                    <Container className="container">
                        <Row className="mt-5">
                            <Col xs={12} sm={12} className="mt-5 mb-5 scroll-anim-750">
                                <h1 className="font-white mt-5">
                                    {translation.header.part1} <br></br><span className="font-divelit-lemon">{translation.header.part2}</span>
                                </h1>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col xs={12} sm={12} className="mt-lg-5 mb-lg-5 scroll-anim-1000">
                                <BtnBgLemonContact></BtnBgLemonContact>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div> 
        </>
    );
}
