// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files




/**
 * MKitMoto section
 * motivation why we ahve developed m-kit
 * @returns HTML code with MKitMoto section for M-KIT page
 */
export default function MKitMoto() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>  
            <div className="bg-divelit-grey-05">
                <Container className="pt-3 pt-md-4">
                    <Container className="pt-3 pt-md-4 pb-2 pb-md-0">
                        <Row className="font-divelit-grey-50 fst-italic pb-3 pb-md-5 mb-3 mb-md-5 fade-in-750-down-to-top">  
                            <Col md={1} xl={2} className="pe-0">
                            </Col>
                            <Col xs={2} sm={2} md={1} className="pe-0">
                                <table className="h-100 cybersec-training-moto-quotation-bottom">
                                    <tbody>
                                        <tr>
                                            <td className="align-bottom" style={{fontSize: ""}}><span style={{fontSize: ""}}>„</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col xs={8} sm={8} md={8} xl={6} className="text-center mx-auto">
                                <div className="me-md-5">
                                    <h3 className="fst-italic me-md-5">
                                        {translation.moto.part1}
                                    </h3>
                                </div>
                                <div className="ms-md-5">
                                    <div className="ms-md-5">
                                        <h3 className="fst-italic ms-md-5">
                                            {translation.moto.part2}
                                        </h3>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} sm={2} md={1} className="ps-0">
                                <table className="h-100 cybersec-training-moto-quotation-top">
                                    <tbody>
                                        <tr>
                                            <td className="align-baseline" style={{fontSize: ""}}><span style={{fontSize: ""}}>“</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col md={1} xl={2} className="pe-0"></Col>

                        </Row>
                    </Container>
                </Container>
            </div>
        </>
    );
}
