// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files

// components
import MKit from "./components/m-kit/m-kit";



/**
 * HomeProducts section
 * lists our products/news
 * @returns HTML code for HomeProducts section for HOME page
 */
export default function HomeProducts() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            <div className="bg-white pb-5">
                <Container className="pt-3 pt-lg-5">
                    <Row className="text-center">
                        <Col xs={12} sm={12} className="mx-auto">
                            <div className="scroll-anim-750">
                                <h3 className="font-divelit-grey-50 mt-3 mt-lg-5 mb-3 mb-lg-5">{translation.header}</h3>
                            </div>
                        </Col>
                    </Row>

                    <MKit></MKit>
                </Container>
            </div>
        </>
    );
}
