// utils
import React, { useEffect } from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, getUrlPrefix, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

//files
import square_black from "../../../../files/icons/rency/square_black.svg";

// sections
import MkitHero from "./components/m-kit-hero/m-kit-hero";
import MKitMoto from "./components/m-kit-moto/m-kit-moto";
import MKitContact from "./components/m-kit-contact/m-kit-contact";
import MKitMartin from "./components/m-kit-martin/m-kit-martin";
import MKitDocuments from "./components/m-kit-documents/m-kit-documents";



/**
 * MKIT page contains info about our M-KIT product
 * @returns MKIT page HTML
 */
export default function Index() {


    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;
    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    /**
     * sets ARTICLE container height so without content it has at least the height required to force FOOTER to be on the bottom of the screen viewport not right bellow latest article with grey backgound covering the rest of the PAGE
     */
    function articleContainerHeight() {
        // inner window height - our reference point
        let windowHeight = window.innerHeight;
        let page404Container = document.getElementById("page404Container");

        // page header height - this needs to be substracted from WINDOW height
        //let tagHeader = document.getElementById("tagHeader");
        //let tagHeaderHeight = tagHeader?.offsetHeight;
        
        // footer height - this also needs to be substracted from WINDOW height
        let footer = document.getElementById("footer");
        let footerHeight = footer?.offsetHeight;

        // total height to be substracted form WINDOW height
        let totalHeight = 0
        if(footerHeight) {
            totalHeight = footerHeight;
        };

        // our final
        if(page404Container !== null) {
            page404Container.style.height = String(windowHeight - totalHeight) + "px";
        }     
    };

    useEffect(() => {
        articleContainerHeight();
    })
    window.addEventListener("resize", (event) => {
        articleContainerHeight();
    });

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }
    
    return (
        <>
            <MkitHero></MkitHero>
            <MKitMoto></MKitMoto>
            <MKitMartin></MKitMartin>
            <MKitDocuments></MKitDocuments>
            <MKitContact></MKitContact>
        </>
    );
}
