// utils
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
//import '../node_modules/font-awesome/css/font-awesome.css';
import { Col, Container, Row } from "react-bootstrap";


//import 'vanilla-cookieconsent';

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

import axios from "axios";

// Custom utils
import { LANGUAGES } from "./utils/langUtils";
import { globalLoading } from "./components/global";

// ALL PAGES
import Home from "./pages/home/index";
import Software from "./pages/software/index";
import Embedded from "./pages/embedded/index";
import EmbeddedProductsMkit from "./pages/embedded/products/m-kit/index";
import Cybersec from "./pages/cybersec/index";
//import Uwb from "./pages/uwb/index";
//import OurWork from "./pages/our-work/index";
//import LinetServiceApp from "./pages/our-work/linet-service-app/index";
import Contact from "./pages/contact/index";
import Career from "./pages/career/index";
//import Blog from "./pages/blog/home/index";
//import Article from "./pages/blog/article/index";
//import Tag from "./pages/blog/tag/index";
import Err404 from "./pages/error-404/404";

// COMPONENTS FOR ALL PAGES
import Footer from "./components/footer";
import Navbar from "./components/navbar";

// SRCIPTS FOR ALL PAGES
import ScrollToTop from "./components/scroll-to-top";

// CSS files

/** SHARED CSS
 * (copy of the files bellow is also located in internal system for BLOG EDITOR)
 * when making change to this files DO NOT RENAME any CLASSES and make sure that 
 * those files are also updated (overwritten) in internal system as well 
 */
import "./files/shared/css/article-img.css";
import "./files/shared/css/font.css";
import "./files/shared/css/header.css";
import "./files/shared/css/link.css";
import "./files/shared/css/paragraph.css";


// LOCAL CSS

// animations
import "./files/css/animation/transition-animation.css";
import "./files/css/animation/zoom.css";
import "./files/css/animation/fade-in.css";

// blog
import "./files/css/blog/article.css";
import "./files/css/blog/blog-latest.css";
import "./files/css/blog/blog-older.css";

// button
import "./files/css/button/button.css";

// career
import "./files/css/career/career-img.css";
import "./files/css/career/career-team.css";
import "./files/css/career/career-how-it-works.css";
import "./files/css/career/career-join-us.css";
import "./files/css/career/career-work-position.css";
import "./files/css/career/career-recruitment.css";

// contact
import "./files/css/contact/contact-form.css";
import "./files/css/contact/contact-address.css";
import "./files/css/contact/contact-team.css";

// cybersec
import "./files/css/cybersec/cybersec-embedded.css";
import "./files/css/cybersec/cybersec-hero.css";
import "./files/css/cybersec/cybersec-management.css";
import "./files/css/cybersec/cybersec-services.css";
import "./files/css/cybersec/cybersec-training.css";

// embedded
import "./files/css/embedded/embedded.css"
import "./files/css/embedded/embedded-hw.css"
import "./files/css/embedded/embedded-sw.css"

// footer
import "./files/css/footer/footer.css";

// form
import "./files/css/form/form.css";

// home
import "./files/css/home/home-hero.css";
import "./files/css/home/home-experts.css";
import "./files/css/home/home-partners.css";
import "./files/css/home/home-start.css";
import "./files/css/home/home-services.css";

// navbar
import "./files/css/navbar/lang-button.css";
import "./files/css/navbar/navbar-transparent.css";
import "./files/css/navbar/navbar.css";
import "./files/css/navbar/dropdown.css";

// our-work
import "./files/css/our-work/our-work-hero.css";
import "./files/css/our-work/our-work.css";
import "./files/css/our-work/project.css";

// page-404
import "./files/css/page-404/page-404.css";

// products
import "./files/css/products/products-mkit.css"

// software
import "./files/css/software/software.css";

// style
import "./files/css/style/colors.css";
import "./files/css/style/sepparator.css";
import "./files/css/style/style.css";



let url = window.location.href;

/**
 * 
 * @returns 
 */
export default function App() {
    /*
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);

    if(isMobileDevice) {
        console.log("MOBILE device");
    }
    else {
        console.log("DESKTOP device");
    }
    */

    /**
     * Theese observers are checking if an observed element have intersection with current viewport
     * if TRUE then fade-in animation is applied on this element and scroll-anim class with "hidden" property is removed from this element
     */
    let observer_250 = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
        // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                entry.target.classList.add('fade-in-250-down-to-top');
                entry.target.classList.remove('scroll-anim-250-hidden');
                entry.target.classList.remove('scroll-anim-250');
                observer_250.unobserve(entry.target);
            }
        });
    });
    let observer_500 = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
            // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                entry.target.classList.add('fade-in-500-down-to-top');
                entry.target.classList.remove('scroll-anim-500-hidden');
                entry.target.classList.remove('scroll-anim-500');
                observer_500.unobserve(entry.target);
            }
        });
    });
    let observer_750 = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
            // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                entry.target.classList.add('fade-in-750-down-to-top');
                entry.target.classList.remove('scroll-anim-750-hidden');
                entry.target.classList.remove('scroll-anim-750');
                observer_750.unobserve(entry.target);
            }
        });
    });
    let observer_1000 = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
            // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                entry.target.classList.add('fade-in-1000-down-to-top');
                entry.target.classList.remove('scroll-anim-1000-hidden');
                entry.target.classList.remove('scroll-anim-1000');
                observer_1000.unobserve(entry.target);
            }
        });
    });
    let observer_1250 = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
            // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                entry.target.classList.add('fade-in-1250-down-to-top');
                entry.target.classList.remove('scroll-anim-1250-hidden');
                entry.target.classList.remove('scroll-anim-1250');
                observer_1250.unobserve(entry.target);
            }
        });
    });
    let observer_1500 = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
            // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                entry.target.classList.add('fade-in-1500-down-to-top');
                entry.target.classList.remove('scroll-anim-1500-hidden');
                entry.target.classList.remove('scroll-anim-1500');
                observer_1500.unobserve(entry.target);
            }
        });
    });
    let observer_1750 = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
            // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                entry.target.classList.add('fade-in-1750-down-to-top');
                entry.target.classList.remove('scroll-anim-1750-hidden');
                entry.target.classList.remove('scroll-anim-1750');
                observer_1750.unobserve(entry.target);
            }
        });
    });
    let observer_2000 = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
            // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                entry.target.classList.add('fade-in-2000-down-to-top');
                entry.target.classList.remove('scroll-anim-2000-hidden');
                entry.target.classList.remove('scroll-anim-2000');
                observer_2000.unobserve(entry.target);
            }
        });
    });
    let observer_2250 = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
            // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                entry.target.classList.add('fade-in-2250-down-to-top');
                entry.target.classList.remove('scroll-anim-2250-hidden');
                entry.target.classList.remove('scroll-anim-2250');
                observer_2250.unobserve(entry.target);
            }
        });
    });
    let observer_2500 = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
            // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                entry.target.classList.add('fade-in-2500-down-to-top');
                entry.target.classList.remove('scroll-anim-2500-hidden');
                entry.target.classList.remove('scroll-anim-2500');
                observer_2500.unobserve(entry.target);
            }
        });
    });
    let observer_2750 = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
            // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                entry.target.classList.add('fade-in-2750-down-to-top');
                entry.target.classList.remove('scroll-anim-2750-hidden');
                entry.target.classList.remove('scroll-anim-2750');
                observer_2750.unobserve(entry.target);
            }
        });
    });
    let observer_3000 = new IntersectionObserver(entries => {
        // Loop over the entries
        entries.forEach(entry => {
            // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                entry.target.classList.add('fade-in-3000-down-to-top');
                entry.target.classList.remove('scroll-anim-3000-hidden');
                entry.target.classList.remove('scroll-anim-3000');
                observer_3000.unobserve(entry.target);
            }
        });
    });


    /** 
     * document.querySelectorAll('.scroll-anim-250')! tells that it will never be NULL (pragrammer takes the responsibility) 
     * 
     * creates corresponding observer for every found scroll-anim class
    */
    if (
        document.querySelector('.scroll-anim-250') !== null ||
        document.querySelector('.scroll-anim-500') !== null || 
        document.querySelector('.scroll-anim-750') !== null || 
        document.querySelector('.scroll-anim-1000') !== null || 
        document.querySelector('.scroll-anim-1250') !== null || 
        document.querySelector('.scroll-anim-1500') !== null ||
        document.querySelector('.scroll-anim-1750') !== null ||
        document.querySelector('.scroll-anim-2000') !== null ||
        document.querySelector('.scroll-anim-2250') !== null ||
        document.querySelector('.scroll-anim-2500') !== null ||
        document.querySelector('.scroll-anim-2750') !== null ||
        document.querySelector('.scroll-anim-3000') !== null
    )
    {
        let entries_250 = (document.querySelectorAll('.scroll-anim-250'));
        entries_250.forEach(entry => {
            // If the element is visible
            entry.classList.add('scroll-anim-250-hidden');

            observer_250.observe(entry);
        });
        let entries_500 = (document.querySelectorAll('.scroll-anim-500'));
        entries_500.forEach(entry => {
            // If the element is visible
            entry.classList.add('scroll-anim-500-hidden');

            observer_500.observe(entry);
        });
        let entries_750 = (document.querySelectorAll('.scroll-anim-750'));
        entries_750.forEach(entry => {
            // If the element is visible
            entry.classList.add('scroll-anim-750-hidden');

            observer_750.observe(entry);
        });
        let entries_1000 = (document.querySelectorAll('.scroll-anim-1000'));
        entries_1000.forEach(entry => {
            // If the element is visible
            entry.classList.add('scroll-anim-1000-hidden');

            observer_1000.observe(entry);
        });
        let entries_1250 = (document.querySelectorAll('.scroll-anim-1250'));
        entries_1250.forEach(entry => {
            // If the element is visible
            entry.classList.add('scroll-anim-1250-hidden');

            observer_1250.observe(entry);
        });
        let entries_1500 = (document.querySelectorAll('.scroll-anim-1500'));
        entries_1500.forEach(entry => {
            // If the element is visible
            entry.classList.add('scroll-anim-1500-hidden');

            observer_1500.observe(entry);
        });
        let entries_1750 = (document.querySelectorAll('.scroll-anim-1750'));
        entries_1750.forEach(entry => {
            // If the element is visible
            entry.classList.add('scroll-anim-1750-hidden');

            observer_1750.observe(entry);
        });
        let entries_2000 = (document.querySelectorAll('.scroll-anim-2000'));
        entries_2000.forEach(entry => {
            // If the element is visible
            entry.classList.add('scroll-anim-2000-hidden');

            observer_2000.observe(entry);
        });
        let entries_2250 = (document.querySelectorAll('.scroll-anim-2250'));
        entries_2250.forEach(entry => {
            // If the element is visible
            entry.classList.add('scroll-anim-2250-hidden');

            observer_2250.observe(entry);
        });
        let entries_2500 = (document.querySelectorAll('.scroll-anim-2500'));
        entries_2500.forEach(entry => {
            // If the element is visible
            entry.classList.add('scroll-anim-2500-hidden');

            observer_2500.observe(entry);
        });
        let entries_2750 = (document.querySelectorAll('.scroll-anim-2750'));
        entries_2750.forEach(entry => {
            // If the element is visible
            entry.classList.add('scroll-anim-2750-hidden');

            observer_2750.observe(entry);
        });
        let entries_3000 = (document.querySelectorAll('.scroll-anim-3000'));
        entries_3000.forEach(entry => {
            // If the element is visible
            entry.classList.add('scroll-anim-3000-hidden');

            observer_3000.observe(entry);
        });
    } 

    const [loading, setLoading] = useState(false);

    document.body.classList.add('style-bg');

    // Is responsible for managing transition animation on PAGE RELOAD
    window.addEventListener("DOMContentLoaded", () => {
        requestAnimationFrame(()=>{
        
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 1000);                    
          
        });
    }, true);

    /**
     * Is responsible for checking global loading FLAG if this FLAG is set to TRUE than some data from Internal system or other source 
     * are being loaded which means that animation should remain on the screen until this flag is changed to FALSE value when all resoources are LOADED
     */
    function checkLoading() {
        if(globalLoading.value === true) {
            //console.log("checkFlag:", globalLoading.value);
            /** this checks the flag every 100 milliseconds */
            window.setTimeout(checkLoading, 100); 
           
        } else {
          /* do something*/
          setLoading(false);
        }
    }

    /**
     * Is responsible for managing animation when changing PAGE
     */
    document.body.addEventListener('click', ()=>{

        requestAnimationFrame(()=>{
            /** actual URL is different than last saved URL */
            if(url !== window.location.href){

                /** looking for '#' character in url - it is present when url leads to the section on same page */
                let fullUrl = window.location.href;
                /** returns '-1' if '#' was not found, otherwise returns index of '#' */
                let hashCharacterIndex = fullUrl.indexOf("#");
                
                /** if there is not '#' character in new URL that LINK leads to different page so ANIMATION is ENABLED  */
                if(hashCharacterIndex === -1) {

                    url = window.location.href
                    /** START the ANIMATION */
                    setLoading(true);                    
                    
                    /** ANIMATION will end after 800 ms if there are no data being loaded which is checked by checkLoading() function */
                    setTimeout(() => {
                        checkLoading();
                    }, 800);
                }
                /* if there is '#' in new URL, the LINK leads to some section of the current page so ANIMATION is DISABLED */
                else if (hashCharacterIndex > 0)
                {

                }

            }
        });
    }, true);



    let pathArray = null;
    let page_lang:any = null;
    /**
     * when user clicks cookies dialog language is updated => on language switch it means that dialog will be shown in new language
     */
    document.body.addEventListener('click', ()=>{
        // checks which language is selected after click and sets Cookies dialog to same language as is active on page
        pathArray = window.location.pathname.split('/');
        if(pathArray[1] === "en"){
          page_lang = "en";
        }
        else if(pathArray[1] === "de"){
          page_lang = "de";
        }
        else{
          page_lang = "cs";
        }
    }, true);
    
    
    window.addEventListener('DOMContentLoaded', function(){
        
        // checks which language is selected after DOMContentLoaded and sets Cookies dialog to same language as is active on page
        pathArray = window.location.pathname.split('/');
        if(pathArray[1] === "en"){
          page_lang = "en";
        }
        else if(pathArray[1] === "de"){
          page_lang = "de";
        }
        else{
          page_lang = "cs";
        }

    });


    React.useEffect(() => {
        CookieConsent.run({
            // root: 'body',
            // autoShow: true,
            // disablePageInteraction: true,
            // hideFromBots: true,
            // mode: 'opt-in',
            revision: 1,
            cookie: {
                name: 'cc_cookie',
                // domain: location.hostname,
                // path: '/',
                // sameSite: "Lax",
                // expiresAfterDays: 365,
            },

            // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
            guiOptions: {
                consentModal: {
                    layout: 'cloud inline',
                    position: 'bottom center',
                    equalWeightButtons: true,
                    flipButtons: false
                },
                preferencesModal: {
                    layout: 'box',
                    equalWeightButtons: true,
                    flipButtons: false
                }
            },

            onFirstConsent: ({cookie}) => {
                // callback triggered only once on the first accept/reject action

                console.log('onFirstConsent fired', cookie);
                //if (cc?.allowedCategory('analytics')) {
                //    console.log("analytics");
                //    ReactGA.initialize(TRACKING_ID);
                //};

                let data = new FormData();
                
                data.append('categories', JSON.stringify(cookie.categories));
                data.append('consentId', cookie.consentId);
                data.append('consentTimestamp', JSON.stringify(cookie.consentTimestamp));
                data.append('expirationTime', JSON.stringify(cookie.expirationTime));
                data.append('lastConsentTimestamp', JSON.stringify(cookie.lastConsentTimestamp));
                data.append('revision', JSON.stringify(cookie.revision));

                //console.log("DATA categories: ", data.get("categories"));
                //console.log("DATA consentId: ", data.get("consentId"));
                //console.log("DATA consentTimestamp: ", data.get("consentTimestamp"));
                //console.log("DATA expirationTime: ", data.get("expirationTime"));
                //console.log("DATA lastConsentTimestamp: ", data.get("lastConsentTimestamp"));
                //console.log("DATA revision: ", data.get("revision"));


                // DEBUG get all data saved in cc_cookie without first 10 letters "cc_cookie="
                //console.log(document.cookie.match("cc_cookie")?.input?.slice(10));

                //console.log(document.cookie.split('; ').find((row) => row.startsWith('cc_cookie='))?.split('=')[1]);
                ////if(document.cookie.match("cc_cookie")?.input?.slice(10) !== null)
                ////{
                ////    data.append("cc_cookie", document.cookie.match("cc_cookie")?.input?.slice(10)!)
                ////}
                //if(document.cookie.split('; ').find((row) => row.startsWith('cc_cookie='))?.split('=')[1])
                //{
                //    data.append("cc_cookie", document.cookie.split('; ').find((row) => row.startsWith('cc_cookie='))?.split('=')[1]!)
                //}

                axios.post("/api/database/cookie_consent", data, {
                    headers: {
                    "Content-type": "multipart/form-data"
                    }
                })
                .then(function (response) {

                })
                .catch(function (error) {

                });
            },

            onConsent: ({cookie}) => {
                // RUNS ON EACH PAGE REFRESH
                
                /*console.log('onConsent fired!', cookie)

                let data = new FormData();
                
                data.append('categories', JSON.stringify(cookie.categories));
                data.append('consentId', cookie.consentId);
                data.append('consentTimestamp', JSON.stringify(cookie.consentTimestamp));
                data.append('expirationTime', JSON.stringify(cookie.expirationTime));
                data.append('lastConsentTimestamp', JSON.stringify(cookie.lastConsentTimestamp));
                data.append('revision', JSON.stringify(cookie.revision));

                //.log("DATA categories: ", data.get("categories"));
                //.log("DATA consentId: ", data.get("consentId"));
                //.log("DATA consentTimestamp: ", data.get("consentTimestamp"));
                //.log("DATA expirationTime: ", data.get("expirationTime"));
                //.log("DATA lastConsentTimestamp: ", data.get("lastConsentTimestamp"));
                //.log("DATA revision: ", data.get("revision"));

                axios.post("/api/database/cookie_consent", data, {
                    headers: {
                    "Content-type": "multipart/form-data"
                    }
                })
                .then(function (response) {

                })
                .catch(function (error) {

                });*/
            },

            onChange: ({cookie, changedCategories, changedServices}) => {
                console.log('onChange fired!', cookie, changedCategories, changedServices);
                // callback triggered when user changes preferences after consent has already been given

                let data = new FormData();
                
                //console.log('categories:', cookie.categories);
                //console.log('consentId:', cookie.consentId);
                //console.log('consentTimestamp:', cookie.consentTimestamp);
                //console.log('expirationTime:', cookie.expirationTime);
                //console.log('lastConsentTimestamp:', cookie.lastConsentTimestamp);
                //console.log('revision:', cookie.revision);

                data.append('categories', JSON.stringify(cookie.categories));
                data.append('consentId', cookie.consentId);
                data.append('consentTimestamp', JSON.stringify(cookie.consentTimestamp));
                data.append('expirationTime', JSON.stringify(cookie.expirationTime));
                data.append('lastConsentTimestamp', JSON.stringify(cookie.lastConsentTimestamp));
                data.append('revision', JSON.stringify(cookie.revision));

                //console.log("DATA categories: ", data.get("categories"));
                //console.log("DATA consentId: ", data.get("consentId"));
                //console.log("DATA consentTimestamp: ", data.get("consentTimestamp"));
                //console.log("DATA expirationTime: ", data.get("expirationTime"));
                //console.log("DATA lastConsentTimestamp: ", data.get("lastConsentTimestamp"));
                //console.log("DATA revision: ", data.get("revision"));

                //console.log('changed', JSON.parse(String(cookie)));
                // DEBUG get all data saved in cc_cookie without first 10 letters "cc_cookie="
                //console.log(document.cookie.match("cc_cookie")?.input?.slice(10));

                //if(document.cookie.match("cc_cookie")?.input?.slice(10) !== null)
                //{
                //    data.append("cc_cookie", document.cookie.match("cc_cookie")?.input?.slice(10)!)
                //}
                
                
                //console.log(document.cookie.split('; ').find((row) => row.startsWith('cc_cookie='))?.split('=')[1]);
                //if(document.cookie.split('; ').find((row) => row.startsWith('cc_cookie='))?.split('=')[1])
                //{
                //    data.append("cc_cookie", document.cookie.split('; ').find((row) => row.startsWith('cc_cookie='))?.split('=')[1]!)
                //}
                
                axios.post("/api/database/cookie_consent", data, {
                    headers: {
                    "Content-type": "multipart/form-data"
                    }
                })
                .then(function (response) {
                    
                })
                .catch(function (error) {

                });
            },

            onModalReady: ({modalName}) => {
                console.log('ready:', modalName);
            },

            onModalShow: ({modalName}) => {
                console.log('visible:', modalName);
            },

            onModalHide: ({modalName}) => {
                console.log('hidden:', modalName);
            },

            categories: {
                necessary: {
                    enabled: true,  // this category is enabled by default
                    readOnly: true  // this category cannot be disabled
                },
                functional: {},
                analytics: {
                    autoClear: {
                        cookies: [
                            {
                                name: /^_ga/,   // regex: match all cookies starting with '_ga'
                            },
                            {
                                name: '_gid',   // string: exact cookie name
                            }
                        ]
                    },

                    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
                    services: {
                        ga: {
                            label: 'Google Analytics',
                            onAccept: () => {},
                            onReject: () => {}
                        },
                        //youtube: {
                        //    label: 'Youtube Embed',
                        //    onAccept: () => {},
                        //    onReject: () => {}
                        //},
                    }
                },
                ads: {}
            },
            language: {
                default: page_lang,
                translations: {
                    en: {
                        consentModal: {
                            title: 'We use cookies',
                            description: 'This website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. You have the right to accept or deny all but neccessary cookies and you can also change your preferences whenever you want.',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            showPreferencesBtn: 'Manage Individual preferences',
                            // closeIconLabel: 'Reject all and close modal',
                            //footer: `
                            //    <a href="#path-to-impressum.html" target="_blank" rel="noreferrer">Impressum</a>
                            //    <a href="#path-to-privacy-policy.html" target="_blank" rel="noreferrer">Privacy Policy</a>
                            //`,
                            footer: `
                                <a href="/pdf/GDPR_EN.pdf" target="_blank" rel="noreferrer">Privacy Policy</a>
                            `,
                        },
                        preferencesModal: {
                            title: 'Manage cookie preferences',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            savePreferencesBtn: 'Accept current selection',
                            closeIconLabel: 'Close modal',
                            serviceCounterLabel: 'Service|Services',
                            sections: [
                                {
                                    title: 'Your Privacy Choices',
                                    description: `Cookies are very small text files that are stored on your computer when you visit a website. We use cookies for a variety of purposes and to enhance your online experience on our website. <br><br> In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices. <br><br> <a href="/pdf/GDPR_EN.pdf" target="_blank" rel="noreferrer">Privacy Policy</a>`,
                                },
                                {
                                    title: 'Strictly Necessary',
                                    description: 'These cookies are essential to provide you with services available through our website and to enable you to use certain features of our website. <br><br> Without these cookies, we cannot provide you certain services on our website.',

                                    //this field will generate a toggle linked to the 'necessary' category
                                    linkedCategory: 'necessary',
                                    cookieTable: {
                                        caption: "Cookie table",
                                        headers: {
                                            name: 'Cookie',
                                            domain: 'Domain',
                                            expiration: 'Expiration',
                                            desc: 'Description'
                                        },
                                        body: [
                                            {
                                                name: 'cc_cookie',
                                                domain: 'divelit.cz',
                                                expiration: '6 months',
                                                desc: 'Stores information about which cookie categories were accepted/denied.',
                                            },
                                        ]
                                    }
                                },
                                {
                                    title: 'Functional',
                                    description: 'These cookies are used to provide you with a more personalized experience on our website and to remember choices you make when you use our website. <br><br> For example, we may use functionality cookies to remember your language preferences or remember your login details.',

                                    //this field will generate a toggle linked to the 'functional' category
                                    linkedCategory: 'functional',
                                },
                                {
                                    title: 'Performance and Analytics',
                                    description: 'These cookies are used to collect information to analyse the traffic to our website and how visitors are using our website. <br><br> These cookies are used to collect information to analyse the traffic to our website and how visitors are using our website. <br><br> The information collected through these tracking and performance cookies do not identify any individual visitor.',
                                    linkedCategory: 'analytics',
                                    cookieTable: {
                                        caption: 'Cookie table',
                                        headers: {
                                            name: 'Cookie',
                                            domain: 'Domain',
                                            expiration: 'Expiration',
                                            desc: 'Description'
                                        },
                                        body: [
                                            {
                                                name: '_ga*',
                                                domain: 'google.com',
                                                expiration: '1 year',
                                                desc: 'Used to store and count pageviews.',
                                            },
                                            {
                                                name: '_ga',
                                                domain: 'google.com',
                                                expiration: '2 years',
                                                desc: 'Used to store and count pageviews.',
                                            }
                                        ]
                                    }
                                },
                                {
                                    title: 'Targeting and Advertising',
                                    description: `These cookies are used to show advertising that is likely to be of interest to you based on your browsing habits. <br><br> These cookies, as served by our content and/or advertising providers, may combine information they collected from our website with other information they have independently collected relating to your web browser's activities across their network of websites. <br><br> If you choose to remove or disable these targeting or advertising cookies, you will still see adverts but they may not be relevant to you.`,
                                    linkedCategory: 'ads',
                                },
                                {
                                    title: 'More information',
                                    description: 'For any queries in relation to our policy on cookies and your choices, please <a href="contact">contact us</a>'
                                }
                            ]
                        }
                    },
                    cs: {
                        consentModal: {
                            title: 'Používáme soubory cookies',
                            description: 'Tato webová stránka používá nezbytné cookie soubory pro zajištění správného fungování a také sledovací cookie soubory, pro pochopení toho jak s naším webem pracujete. Tyto sledovací cookie soubory budou vytvořeny až na základě Vašeho souhlasu. Máte právo přijmout nebo odmítnout všechny cookie soubory, kromě nezbytných pro fungování webu, a také můžete své nastavení kdykoliv změnit.',
                            acceptAllBtn: 'Přijmout všechny',
                            acceptNecessaryBtn: 'Odmítnout všechny',
                            showPreferencesBtn: 'Upravit mé předvolby',
                            // closeIconLabel: 'Reject all and close modal',
                            footer: `
                                <a href="/pdf/GDPR_CS.pdf" target="_blank" rel="noreferrer">Ochrana soukromí</a>
                            `,
                        },
                        preferencesModal: {
                            title: 'Nastavení souborů cookie',
                            acceptAllBtn: 'Přijmout všechny',
                            acceptNecessaryBtn: 'Odmítnout všechny',
                            savePreferencesBtn: 'Uložit nastavení',
                            closeIconLabel: 'Zavřít nabídku',
                            serviceCounterLabel: 'Service|Services',
                            sections: [
                                {
                                    title: 'Vaše nastavení soukromí',
                                    description: `Soubory cookies jsou velmi malé textové soubory, které se ukládají do vašeho zařízení při navštěvování webových stránek. Soubory cookies používáme pro různé účely a pro vylepšení vašeho online zážitku na webové stránce. <br><br> V tomto panelu můžete upravit nastavení ovlivňující jakým způsobem bude docházet ke zpracování vašich osobních informací. Provedená nastavení si můžete kdykoliv zkontrolovat a změnit otevřením tohoto panelu přes odkaz v záhlaví stránky. Pro odmítnutí Vašeho souhlasu s vybranými způsoby zpracování dat, použijte přepínače níže pro jednotlivé kategorie, nebo tlačítko "Odmítnout všechny" a potvrdit uložení Vašeho nastavení. <br><br> <a href="/pdf/GDPR_CS.pdf" target="_blank" rel="noreferrer">Ochrana soukromí</a>`,
                                },
                                {
                                    title: 'Bezpodmínečně nutné',
                                    description: 'Tyto soubory cookies jsou nezbytné k tomu, abychom vám mohli poskytovat služby dostupné prostřednictvím našeho webu a abychom vám umožnili používat určité funkce našeho webu. <br><br> Bez těchto cookies vám nemůžeme na naší webové stránce poskytovat určité služby.',

                                    //this field will generate a toggle linked to the 'necessary' category
                                    linkedCategory: 'necessary',
                                    cookieTable: {
                                        caption: "Cookie table",
                                        headers: {
                                            name: 'Název',
                                            domain: 'Doména',
                                            expiration: 'Expirace',
                                            desc: 'Popis'
                                        },
                                        body: [
                                            {
                                                name: 'cc_cookie',
                                                domain: 'divelit.cz',
                                                expiration: '6 měsíců',
                                                desc: 'Ukládá informaci ohledně toho, které kategorie cookies byly přijaty/odmítnuty.',
                                            },
                                        ]
                                    }
                                },
                                {
                                    title: 'Funkční',
                                    description: 'Tyto soubory cookie se používají k tomu, aby vám na našich webových stránkách poskytovaly personalizovaný uživatelský zážitek a aby si pamatovaly vaše volby, které jste použili při používání našich webových stránek. <br><br> Můžeme například používat soubory cookie k zapamatování vašeho jazyka nebo k zapamatování vašich přihlašovacích údajů.',

                                    //this field will generate a toggle linked to the 'functional' category
                                    linkedCategory: 'functional',
                                },
                                {
                                    title: 'Sledovací a výkonnostní',
                                    description: 'Tyto soubory cookies se používají ke shromažďování informací pro analýzu provozu na našich webových stránkách a sledování používání našich webových stránek uživateli. <br><br> Tyto soubory cookies mohou například sledovat věci jako je doba kterou na webu trávíte, nebo stránky, které navštěvujete, což nám pomáhá pochopit, jak pro vás můžeme vylepšit náš web. <br><br> Informace shromážděné prostřednictvím těchto sledovacích a výkonnostních cookies neidentifikují žádné osoby.',
                                    linkedCategory: 'analytics',
                                    cookieTable: {
                                        caption: 'Cookie table',
                                        headers: {
                                            name: 'Název',
                                            domain: 'Doména',
                                            expiration: 'Expirace',
                                            desc: 'Popis'
                                        },
                                        body: [
                                            {
                                                name: '_ga*',
                                                domain: 'google.com',
                                                expiration: '1 rok',
                                                desc: 'Slouží k ukládání a počítání návštěv webu.',
                                            },
                                            {
                                                name: '_ga',
                                                domain: 'google.com',
                                                expiration: '2 rok',
                                                desc: 'Slouží k ukládání a počítání návštěv webu.',
                                            }
                                        ]
                                    }
                                },
                                {
                                    title: 'Cílení a reklama',
                                    description: `Tyto soubory cookie se používají k zobrazování reklamy, která vás pravděpodobně bude zajímat na základě vašich zvyků při procházení. <br><br> Tyto soubory cookie, jsou požadovány námi/nebo poskytovateli reklam, mohou kombinovat informace shromážděné z našich webových stránek s dalšími informacemi, které nezávisle shromáždily z jiných webových stránek, týkající se činností vašeho internetového prohlížeče v rámci jejich reklamní sítě webových stránek. <br><br> Pokud se rozhodnete tyto soubory cookies pro cílení nebo reklamu odstranit nebo deaktivovat, budou se vám reklamy stále zobrazovat, ale nemusí pro vás být nadále personalizované a relevantní.`,
                                    linkedCategory: 'ads',
                                },
                                {
                                    title: 'Další informace',
                                    description: 'V případě jakýchkoliv dotazů ohledně našich zásad týkajících se souborů cookie a vašich možností nás prosím <a href="contact">kontaktujte</a>'
                                }
                            ]
                        }
                    }
                }
            }
        });
    }, []);
    const innerRoutes =
        <Route>
            <Route path="" element={<Home/>}/>
            <Route path="software" element={<Software/>}/>
            <Route path="embedded" element={<Embedded/>}/>
            <Route path="embedded/products/m-kit" element={<EmbeddedProductsMkit/>}/>
            <Route path="cybersec" element={<Cybersec/>}/>
            {/*<Route path="uwb" element={<Uwb/>}/>*/}
            {/*<Route path="our-work" element={<OurWork/>}/>*/}
            {/*<Route path="our-work/linet-service-app" element={<LinetServiceApp/>}/>*/}
            <Route path="contact" element={<Contact/>}/>
            <Route path="career" element={<Career/>}/>
            {/*<Route path="err404" element={<Err404/>}/>
            <Route path="*" element={<Err404/>}/>*/}
            {/*<Route path="blog" element={<Blog/>}/>*/}
            {/*<Route path="blog/article" element={<Article/>}/>*/}
            {/*<Route path="blog/tag" element={<Tag/>}/>*/}
        </Route>

    // solves broken layout of some pages in build
    //const [hasMounted, setHasMounted] = React.useState(false);
    //React.useEffect(() => {
    //  setHasMounted(true);
    //}, []);
    //if (!hasMounted) {
    //  return null;
    //}

    /**
     * According to the value of the 'loading' variable the ANIMATION is being displayed above the PAGE
     */
    return (
        <>
            {/*<div className="transition-animation fade-in-500" style={{display: (loading? ("block") : ("none"))}}>
                <p className="transition-animation-logo">

                </p>
                <div className="transition-animation-inner" >
                
                </div>             
            </div>*/}
            
            {/*<div className="main-content" style={{display: (loading ? ("none") : ("block"))}}>*/}
            <div className="main-content">
                <BrowserRouter>
                    <ScrollToTop/>
                    <Navbar/>

                    <Routes>
                        <Route path="/">
                            {innerRoutes}
                        </Route>
                        <Route path={LANGUAGES.Czech+"/"}>
                            {innerRoutes}
                        </Route>
                        <Route path={LANGUAGES.English+"/"}>
                            {innerRoutes}
                        </Route>
                        <Route path="*" element={<Err404/>}/>

                    </Routes>

                    <Footer/>

                    {/*<div className="bg-divelit-grey-90" style={{height: "50px"}}>
                        <hr className="sepparator-horiz-divelit-grey-30 mb-3"></hr>
                        <Container>
                            <Row>
                                <Col xs={12} sm={12} className="d-flex flex-column flex-lg-row mb-3" style={{paddingTop: "0", paddingBottom: "0"}}>
                                    <div className="align-self-center flex-grow-1 mt-auto mb-auto">
                                        <p className="paragraph-extra-small font-divelit-grey-50 mb-0">&copy; DIVELIT system s.r.o. {new Date().getFullYear()}</p>
                                    </div>
                                    <div className="align-self-center mt-auto mb-auto">
                                        <a data-cc="show-preferencesModal" className="footer-copyright paragraph-extra-small font-divelit-grey-50" style={{fontSize: "0.875rem"}}>Zásady cookies</a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div>*/}


                    {/*<div className="bg-divelit-grey-90" style={{textAlign: "center"}}>
                        <hr className="sepparator-horiz-divelit-grey-30 mb-3"></hr>
                        <div className="container mx-auto w-fit-content" style={{textAlign: "center"}}>
                            <p className="paragraph-extra-small font-divelit-grey-50 mb-0">&copy; DIVELIT system s.r.o. {new Date().getFullYear()}</p>

                            <a data-cc="show-preferencesModal" className="footer-copyright paragraph-extra-small font-divelit-grey-50" style={{fontSize: "0.875rem"}}>Zásady cookies</a>
                        
                        </div>
                    </div>*/}
                </BrowserRouter>
            </div>
      </>
  );
}
