// utils
import React from "react";
//import { useMatch } from "react-router-dom";

// custom utils
//import { getCurrentLanguage, LANGUAGES } from "../../utils/langUtils";

// translations
//import translation_cs from "./lang/cs.json";
//import translation_en from "./lang/en.json";
//import translation_de from "./lang/de.json";

// page sections
import HomeHero from "./components/home-hero/home-hero";
import HomeExperts from "./components/home-experts/home-experts";
import HomePartners from "./components/home-partners/home-partners";
import HomeProducts from "./components/home-products/home-products";
import HomeServices from "./components/home-services/home-services";
import HomeOurWork from "./components/home-our-work/home-our-work";
import HomeCollaboration from "./components/home-collaboration/home-collaboration";
import HomeStart from "./components/home-start/home-start";
import HomeValues from "./components/home-values/home-values";

/**
 * HOME page
 * @returns HOME page
 */
export default function Index() {

    /*const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }*/

    // solves broken layout of some pages in build
    //const [hasMounted, setHasMounted] = React.useState(false);
    //React.useEffect(() => {
    //  setHasMounted(true);
    //}, []);
    //if (!hasMounted) {
    //  return null;
    //}
    
    return (
        <>
            <div className="fade-in-750">
                <HomeHero/>
                <HomeExperts/>
                <HomePartners/>
                {/*<HomeValues/>*/}
                <HomeProducts/>
                <HomeServices/>
                <HomeOurWork/>
                <HomeCollaboration/>
                <HomeStart/>
            </div>
        </>
    );
}
