// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";


// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_lemon from "../../../../files/icons/rency/square_lemon.svg";
import square_lime from "../../../../files/icons/rency/square_lime.svg";
import square_grass from "../../../../files/icons/rency/square_grass.svg";



/**
 * ContactAddress section
 * contains address information about our locations
 * @returns HTML code for ContactAddress section used on CONTACT page
 */
export default function ContactAddress() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            <div className="contact-address-section-bg">
                <Container className="pt-3 pt-md-5 pb-4 pb-md-4 fade-in-1250-down-to-top">
                    <Container className="contact-address-container-padding project-tile-border project-tile-box-shadow-divelit-grey-10 bg-white">
                        <Row className="">
                            <Col xs={12} sm={12} md={3} className="">
                                <div className="fade-in-1500-down-to-top">
                                    <img src={square_lemon} className="mt-3 mt-md-0 mb-3 mb-md-5 d-inline-block home-experts-img" alt={translation.alt.square_lemon}/>
                                    <h5 className="">{translation.brno_office.part1}</h5>
                                    <p className="paragraph-small">{translation.brno_office.part2}</p>
                                    <p className="paragraph-small">{translation.brno_office.part3}</p>
                                    <p className="paragraph-small">{translation.brno_office.part4}</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={4} className="">
                                <div className="fade-in-1750-down-to-top">
                                    <img src={square_lime} className="mt-5 mt-md-0 mb-3 mb-md-5 d-inline-block home-experts-img" alt={translation.alt.square_lime}/>
                                    <h5 className="">{translation.olomouc_office.part1}</h5>
                                    <p className="paragraph-small">{translation.olomouc_office.part2}</p>
                                    <p className="paragraph-small">{translation.olomouc_office.part3}</p>
                                    <p className="paragraph-small">{translation.olomouc_office.part4}</p>
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={5} className="">
                                <div className="fade-in-2000-down-to-top contact-address-company-info">
                                    <img src={square_grass} className="mt-5 mt-md-0 mb-3 mb-md-5 d-inline-block home-experts-img" alt={translation.alt.square_grass}/>
                                    <h5 className="">{translation.registered.company}</h5>
                                    <h5 className="">{translation.registered.company_number}</h5>
                                    <h5 className="">{translation.registered.company_number_2}</h5>
                                    <p className="paragraph-small">{translation.registered.text}</p>
                                    {/*<p className="paragraph-medium"><b>{translation.billing_address.part1}</b></p>*/}
                                    <h5 className="">{translation.billing_address.part1}</h5>
                                    <p className="paragraph-small mb-0">{translation.billing_address.part2}</p>
                                    <p className="paragraph-small">{translation.billing_address.part3}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        </>
    );
}
