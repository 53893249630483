// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import martin_mikulka from "../../../../../../files/images/pages/contact/martin-mikulka.webp";



/**
 * MKitMartin section
 * Martin gives personal story about how and why he have developed the M-Kit
 * @returns HTML code with MKitMartin section for M-KIT page
 */
export default function MKitMartin() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }


    
    return (
        <>  
            <div className="">
                <div className="bg-white pb-3 pb-lg-5">
                    <Container className="pt-3 pt-lg-5 pb-3 pb-lg-5">
                        <Row className="row">
                            <Col xs={12} sm={12} md={5} lg={5} xl={5} xxl={5} className="text-center scroll-anim-750">
                                <img src={martin_mikulka} className="mx-auto p-sm-3 p-md-0 p-xl-0 products-mkit-martin-img" alt={translation.alt.profile_img}></img>
                            </Col>
                            <Col xs={12} sm={12} md={7} lg={7} xl={7} xxl={7} className="p-3 p-md-2 p-lg-3 p-xl-5">
                                <h3 className="scroll-anim-750">
                                    <span style={{fontWeight: "600"}}>{translation.header.part1}</span><span style={{fontSize: "128%"}}>e</span><span style={{fontWeight: "600"}}>{translation.header.part2}</span> <span className="font-divelit-lemon" style={{fontWeight: "600"}}>{translation.header.part3}</span>
                                </h3>
                                <div className="scroll-anim-1000">
                                    <p className="paragraph-small mt-3">{translation.paragraph.part1}</p>
                                    <p className="paragraph-small mt-3">{translation.paragraph.part2}</p>
                                </div>
                                <div className="scroll-anim-1250">
                                    <h5 className="mt-3 mt-md-5">{translation.team.martin_mikulka.name}</h5>
                                    <p className="paragraph-medium">{translation.team.martin_mikulka.role}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}
