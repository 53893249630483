// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileAlt, faShieldHalved, faUserShield, faShield, faShieldAlt, faVirus, faUserSecret, faWifi, faHistory, faEnvelope, faKey, faLock, faDesktop, faGlobeAmericas, faUser, faUserGraduate, faExclamationTriangle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
//import mkit from "../../../../../../files/images/pages/products/m-kit/mkit.png";
import mkit from "../../../../../../files/images/pages/products/m-kit/mkit-top-cropped.png";
import BtnBgLemonEshop from "../../../../../../components/btn/btn-bg-lemon-eshop";

import square_black from  "../../../../../../files/icons/rency/square_black.svg";


/**
 * MKitDocuments section
 * allows users to view/download datasheet / product brief / user manual
 * @returns HTML code with MKitDocuments section for M-KIT page
 */
export default function MKitDocuments() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }


    
    return (
        <>  
            <div className="scroll-anim-1000">
                <div className="bg-white pb-3 pb-lg-5">
                    <Container className="mt-3 mt-lg-5 mb-3 mb-lg-5" style={{marginTop: "2rem"}}>
                            <Row>
                                <Col></Col>                                
                                <Col xs={12} sm={12} md={10} xl={8}>
                                    <Container className="p-3 p-md-4 p-lg-5 project-tile-border project-tile-box-shadow-divelit-grey-10 bg-white">
                                        {/*<Container className="p-3 p-md-4 p-lg-5">*/}
                                        <Row>
                                            <Col xs={12} sm={12} className="">
                                                <div className="d-flex flex-row scroll-anim-750">
                                                {/*<div className="d-flex flex-row justify-content-center align-items-center scroll-anim-750">*/}
                                                    <h4 className="w-fit-content"></h4>
                                                </div>
                                                <div className="d-flex flex-row align-items-center">
                                                    <img src={square_black} className="home-experts-img w-fit-content"></img>
                                                    <h4 className="mb-0 ms-3 w-fit-content">{translation.header.part1}</h4>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                {/*<p className="paragraph-small">
                                                    Zapomeň na spleť kabelů a množství převodníků. M-KIT je nahradí jediným kompaktním zařízením. Je jedno, zda děláte v kanceláři, doma nebo na cestách. Celý váš vývojářský setup máte vždy při sobě. Stačí připojit a můžete okamžitě programovat, ladit nebo testovat.
                                                </p>*/}
                                                <p className="paragraph-small mt-3 mt-md-3 mt-md-5">
                                                    {translation.paragraph.part1}
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3 mt-lg-5 text-center">
                                            <Col xs={12} sm={12} md={6} xl={6} className="me-xl-4">
                                                <a className="link-href products-mkit-href" href={process.env.PUBLIC_URL + '/pdf/' + translation.documents.product_brief} target="_blank"  rel="noreferrer">
                                                    <div className="d-flex flex-row align-items-center mb-3 p-3 p-lg-4 project-tile-border project-tile-box-shadow-divelit-grey-10 bg-white">
                                                        <FontAwesomeIcon icon={faFileAlt} aria-hidden="true" className="font-divelit-grey-50 w-fit-content" style={{fontSize: "2rem"}}/>
                                                        <p className="paragraph-medium font-divelit-grey-50 align-self-center-md w-fit-content mb-0 ms-3">
                                                            Product brief
                                                        </p>
                                                    </div>
                                                </a>
                                                {/*<div className="d-flex flex-row align-items-center mb-3 p-3 p-lg-4 project-tile-border project-tile-box-shadow-divelit-grey-10 bg-white">
                                                    <FontAwesomeIcon icon={faFileAlt} aria-hidden="true" className="font-divelit-grey-50 w-fit-content" style={{fontSize: "2rem"}}/>
                                                    <p className=" paragraph-medium font-divelit-grey-50 align-self-center-md w-fit-content mb-0 ms-3">
                                                        Datasheet
                                                    </p>
                                                </div>
                                                <div className="d-flex flex-row align-items-center mb-3 p-3 p-lg-4 project-tile-border project-tile-box-shadow-divelit-grey-10 bg-white">
                                                    <FontAwesomeIcon icon={faFileAlt} aria-hidden="true" className="font-divelit-grey-50 w-fit-content" style={{fontSize: "2rem"}}/>
                                                    <p className=" paragraph-medium font-divelit-grey-50 align-self-center-md w-fit-content mb-0 ms-3">
                                                        User Manual
                                                    </p>
                                                </div>*/}
                                            </Col>
                                            <Col xs={12} sm={12} md={6} xl={5} className="ms-xl-4">
                                                <img className="pe-0 w-50 d-inline d-md-none h-auto mt-3" alt="translation.alt.divelit_is" style={{position: "initial"}}  src={mkit}/>
                                                <img className="pe-0 w-75 d-none d-md-inline h-auto" alt="translation.alt.divelit_is" style={{position: "initial"}}  src={mkit}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} className="text-center">
                                                <div className="mt-3 mt-md-3 mb-md-5 d-flex flex-row justify-content-center align-items-center scroll-anim-750">
                                                    <h4 className="w-fit-content"></h4>
                                                </div>
                                                <BtnBgLemonEshop></BtnBgLemonEshop>
                                            </Col>
                                        </Row>
                                    </Container>
                                
                                
                                </Col>                                
                                <Col></Col>                                

                            </Row>
                    
                    </Container>
                </div>
            </div>
        </>
    );
}
